import React, { useEffect, useState } from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";
import Item from "../../components/accordion";
import { GetDocs } from "../../api";
import { getMLValue } from "../../helpers/ml";

const NormativeActs = () => {
  const { t, i18n } = useTranslation();

  // const [docs, setDocs] = useState([]);
  // useEffect(() => {
  //   GetDocs().then(({ data }) => {
  //     setDocs(data.filter((doc) => doc.type === "normative_legal_act"));
  //   });
  // }, []);

  const NormativData = [
    {
      name: {
        am: "ՀՀ քաղաքացիական օրենսգիրք",
        ru: "Гражданский кодекс Республики Армения",
        en: "Civil Code of the Republic of Armenia",
      },
      link: "https://www.arlis.am/DocumentView.aspx?DocID=198717",
    },
    {
      name: {
        am: "Օրենքներ",
        ru: "Законы",
        en: "Laws",
      },
      link: "https://www.cba.am/am/SitePages/lawssecurities.aspx",
    },
    {
      name: {
        am: "ՀՀ ԿԲ կանոնակարգեր",
        ru: "Нормативные акты Центрального банка РА",
        en: "Regulations of the Central Bank of RA",
      },
      link: "https://www.cba.am/am/SitePages/regsecurities.aspx",
    },
    {
      name: {
        am: "Հայաստանի ֆոնդային բորսայի կանոններ",
        ru: "Правила Фондовой биржи Армении",
        en: "Rules of the Armenian Stock Exchange",
      },
      link: "https://amx.am/am/regulation/exchange_rules",
    },
    {
      name: {
        am: "Հայաստանի կենտրոնական դեպոզիատրիայի կանոններ",
        ru: "Правила Центрального депозитария Армении",
        en: "Rules of the Central Depository of Armenia",
      },
      link: "https://cda.am/am/regulation/rules",
    },
    // {
    //   name: {
    //     am: "wwwww",
    //     ru: "wwwww",
    //     en: "wwwww",
    //   },
    //   link: "wwwwwwwwww",
    // },
  ];

  return (
    <div className="normativeActs">
      <h1>{t("normativeLActs")}</h1>
      <div
        className="nActs"
        style={{
          justifyContent: NormativData.length > 1 ? "space-between" : "center",
        }}
      >
        {NormativData.map((item, index) => (
          <Item
            key={index}
            text={getMLValue(item.name, i18n.language)}
            link={item.link}
          />
        ))}
      </div>
    </div>
  );
};

export default NormativeActs;
