import React, { useEffect, useState } from "react";
import "./style.scss";
import Item from "../../components/accordion";
import { useTranslation } from "react-i18next";
import { GetDocs } from "../../api";
import { getMLValue } from "../../helpers/ml";
import { documentDownloadURL } from "../../helpers/doc";
import doc1 from "./docs/Արժեթղթերի հաշվառման և հաշվարկային միասնական համակարգի անդամի գործունեության կանոններ.pdf";
import doc2 from "./docs/ՍԱԿԱԳՆԵՐ.pdf";
import doc3 from "./docs/Սիրիուս_Կապիտալ_ՓԲԸ_Արժեթղթերի_փաթեթի_կառավարման-կանոնակարգ-300523.pdf";
import doc4 from "./docs/Սիրիուս_Կապիտալ_ՓԲԸ-Արժեթղթերի_տեղաբաշխման_կանոնակարգ-300523.pdf";
import doc5 from "./docs/Սիրիուս_Կապիտալ_ՓԲԸ-կանոնակարգ-Պահառուական-ծառայությունների-300523.pdf";
import doc6 from "./docs/Սիրիուս_Կապիտալ_ՓԲԸ-կանոնակարգ-Բրոքերային_և_դիլերային_գործունեության-300523.pdf";
import doc7 from "./docs/Սիրիուս_Կապիտալ_ՓԲԸ-կանոնակարգ-փաստաթղթաշրջանառություն-300523.pdf";
import doc8 from "./docs/Սիրիուս_Կապիտալ_ՓԲԸ-կանոնակարգ-ՓԼԱՖ-300523.pdf";
import doc9 from "./docs/Սիրիուս_Կապիտալ-կանոնակարգ-խորհրդատվական_ծառայությունների_մատուցման-300523.pdf";
import doc10 from "./docs/Սիրիուս_Կապիտալ—ՓԲԸ_կանոնակարգ_ՂԱ_որակավորում-130523 (1).pdf";
import doc11 from "./docs/Սիրիուս_Կապիտալ-ՓԲԸ-կանոնակարգ-ներքին-աուդիտ-300523.pdf";
import doc12 from "./docs/Սիրիուս_Կապիտալ-ՓԲԸ-կանոնակարգ-շահերի-բախման-300523.pdf";
import doc13 from "./docs/Պատվերների կատարման քաղաքականություն.pdf";

const InternalActs = () => {
  const { t, i18n } = useTranslation();

  // const [docs, setDocs] = useState([]);
  // useEffect(() => {
  //   GetDocs().then(({ data }) => {
  //     setDocs(data.filter((doc) => doc.type === "internal_legal_act"));
  //   });
  // }, []);

  const InternalData = [
    {
      name: {
        am: "Արժեթղթերի հաշվառման և հաշվարկային միասնական համակարգի անդամի գործունեության կանոններ",
        ru: "Правила деятельности участников единой системы учета и расчетов по ценным бумагам",
        en: "Rules for Members of the Unified Securities Accounting and Settlement System",
      },
      doc: doc1,
    },
    {
      name: {
        am: "Արժեթղթերի հաշվառման և հաշվարկային միասնական համակարգի անդամի գործունեության կանոնակարգի համաձայն մատուցվող ծառայությունների գները և վճարման պայմանները",
        ru: "Тарифы и условия оплаты услуг, предоставляемых в соответствии с правилами деятельности участников единой системы учета и расчетов по ценным бумагам",
        en: "Prices and Payment Terms for Services Provided per the Unified Securities Accounting and Settlement System Rules",
      },
      doc: doc2,
    },
    {
      name: {
        am: "Արժեթղթերի փաթեթի կառավարման կանոնակարգ",
        ru: "Правила управления портфелем ценных бумаг",
        en: "Securities Portfolio Management Rules",
      },
      doc: doc3,
    },
    {
      name: {
        am: "Արժեթղթերի տեղաբաշխման կանոնակարգ",
        ru: "Правила распределения ценных бумаг",
        en: "Securities Distribution Rules",
      },
      doc: doc4,
    },
    {
      name: {
        am: "Պահառուական ծառայությունների մատուցման կանոնակարգ",
        ru: "Правила предоставления депозитарных услуг",
        en: "Rules for Providing Custodian Services",
      },
      doc: doc5,
    },
    {
      name: {
        am: "Բրոքերային (դիլերային) գործունեության կանոնակարգ",
        ru: "Правила брокерской (дилерской) деятельности",
        en: "Brokerage (Dealer) Activity Rules",
      },
      doc: doc6,
    },
    {
      name: {
        am: "Ներդրումային և ոչ հիմնական ծառայությունների մատուցման հետ կապված փաստաթղթաշրջանառության կազմակերպման կանոնակարգ",
        ru: "Правила документооборота, связанные с предоставлением инвестиционных и неосновных услуг",
        en: "Rules for Document Circulation Related to Investment and Non-Core Services",
      },
      doc: doc7,
    },
    {
      name: {
        am: "Փողերի լվացման և ահաբեկչության ֆինանսավորման դեմ պայքարի կանոնակարգ",
        ru: "Правила противодействия отмыванию денег и финансированию терроризма",
        en: "Anti-Money Laundering and Counter-Terrorism Financing Rules",
      },
      doc: doc8,
    },
    {
      name: {
        am: "Խորհրդատվական ծառայությունների մատուցման կանոնակարգ",
        ru: "Правила предоставления консультационных услуг",
        en: "Rules for Providing Advisory Services",
      },
      doc: doc9,
    },
    {
      name: {
        am: "Ղեկավարների որակավորման և մասնագիտական համապատասխանության չափանիշները, դրանց գնահատման սկզբունքներն ու ընթացակարգերը, ղեկավարների գրանցման մերժման հիմքերը",
        ru: "Критерии квалификации и профессионального соответствия руководителей, принципы и процедуры их оценки, основания для отказа в регистрации руководителей",
        en: "Standards for Manager Qualifications and Professional Competence, Evaluation Principles and Procedures, Grounds for Manager Registration Denial",
      },
      doc: doc10,
    },
    {
      name: {
        am: "Ներքին աուդիտի գործունեության կանոնակարգ",
        ru: "Правила внутреннего аудита",
        en: "Internal Audit Rules",
      },
      doc: doc11,
    },
    {
      name: {
        am: "Շահերի բախման բացառման ու կանխարգելման կանոնակարգ",
        ru: "Правила предотвращения конфликта интересов",
        en: "Conflict of Interest Prevention Rules",
      },
      doc: doc12,
    },
    {
      name: {
        am: "Պատվերների կատարման քաղաքականություն",
        ru: "Политика исполнения заказов",
        en: "Order Execution Policy",
      },
      doc: doc13,
    },
  ];

  return (
    <div className="internalActs">
      <h1>{t("internalLActs")}</h1>

      <div
        className="iActs"
        style={{
          justifyContent: InternalData.length > 1 ? "space-between" : "center",
        }}
      >
        {InternalData.map((item, index) => (
          <Item
            key={index}
            text={getMLValue(item.name, i18n.language)}
            downloadUrl={item.doc}
            // downloadUrl={documentDownloadURL(
            //   getMLValue(item.doc, i18n.language)
            // )}
          />
        ))}
      </div>
    </div>
  );
};

export default InternalActs;
