import React, { useRef } from "react";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import ResearchImg from "../../assets/Research.jpg";
import Lock from "../../assets/Lock.svg";
import LockBlack from "../../assets/LockBlack.svg";
import { getMLValue } from "../../helpers/ml";
import { useTranslation } from "react-i18next";
import { galleryImgURL } from "../../helpers/image";
import { format, parse } from "date-fns";

const ResearchCard = ({
  title,
  subtitle,
  categories,
  onCategoryClick,
  image,
  date,
  alias,
  authors,
}) => {
  const { t, i18n } = useTranslation();
  const newsCardRef = useRef(null);
  const navigate = useNavigate();

  const handleNavigate = () => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
    navigate(`/research/${alias}`);
  };

  return (
    <div
      ref={newsCardRef}
      onClick={handleNavigate}
      style={{
        backgroundImage: `url(${galleryImgURL(image)})`,
      }}
      className="researchCard"
    >
      <div className="mobile-research-background">
        <img src={galleryImgURL(image)} alt="background" />
      </div>
      <div className="researchHeader">
        <div className="researchHeaderCategories">
          {categories.map((category) => (
            <p
              key={category.id}
              onClick={(e) => {
                e.stopPropagation();
                onCategoryClick(category.id);
              }}
            >
              {getMLValue(category.name, i18n.language)}
            </p>
          ))}
        </div>

        <img className="lockWhite" src={Lock} alt="Lock" />
        <img className="lockBlack" src={LockBlack} alt="Lock" />
      </div>
      <div className="researchContent">
        <p>{getMLValue(title, i18n.language)}</p>
        <div className="offcut"></div>
        <span>{getMLValue(subtitle, i18n.language)}</span>
        <div className="bottom-block">
          <p>
            {date} • {authors.map((m) => getMLValue(m.name)).join(", ")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ResearchCard;
