import React, { FC } from "react";
import "./style.scss";
import Remove from "../../assets/RemoveCategory.svg";
import Checked from "../../assets/Checked.svg";

const ResearchCategory = ({ name, active, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={
        active
          ? "research-category research-category-active"
          : "research-category"
      }
    >
      <button
        className="checkbox-btn"
        style={{ backgroundColor: active ? "#000f30" : "white" }}
      >
        <img src={Checked} alt="Checked" />
      </button>
      <label>{name}</label>
    </div>
  );
};

export default ResearchCategory;
