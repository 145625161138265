import React, { useRef, memo, useEffect, useState } from "react";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import Clock from "../../assets/Clock.svg";
import Eye from "../../assets/Eye.svg";
import Calendar from "../../assets/Calendar.svg";
import { getMLValue } from "../../helpers/ml";
import { useTranslation } from "react-i18next";
import { galleryImgURL } from "../../helpers/image";

const BlogCard = memo(
  ({
    categories,
    onCategoryClick,
    title,
    subtitle,
    duration,
    views,
    date,
    image,
    alias,
  }) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const handleNavigate = () => {
      window.scrollTo({
        top: 0,
        behavior: "instant",
      });
      navigate(`/blog/${alias}`);
    };

    return (
      <div className="blogCard">
        <div className="blogCardContainer">
          <div className="blogCardHeader">
            {categories.map((category) => (
              <p
                key={category.id}
                onClick={(e) => {
                  e.stopPropagation();
                  onCategoryClick(category.id);
                }}
              >
                {getMLValue(category.name, i18n.language)}
              </p>
            ))}
          </div>
          <div className="blogCardMain">
            <p onClick={handleNavigate}>{getMLValue(title, i18n.language)}</p>
            <span>{getMLValue(subtitle, i18n.language)}</span>
          </div>
          <div className="blogCardFooter">
            <button onClick={handleNavigate}>{t("readMore")}</button>

            <div>
              <p>
                <img src={Clock} alt="Clock" />
                {duration} {t("minBlog")}.
              </p>
              {/* <p>
                <img src={Eye} alt="Eye" />
                {views}
              </p> */}
              <p>
                <img src={Calendar} alt="Calendar" />
                {date}
              </p>
            </div>
          </div>
        </div>

        <div className="cardImg">
          <img
            className="cardImg"
            src={galleryImgURL(image)}
            alt="card-image"
          />
        </div>
      </div>
    );
  }
);

export default BlogCard;
