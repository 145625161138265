import React, { useEffect, useState } from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";
import ResearchCard from "../../components/researchCard";
import { GetBlog, GetBlogCategories, GetMembers } from "../../api";
import Loading from "../../components/loading";
import { Fade } from "react-awesome-reveal";
import { format, parse } from "date-fns";
import BlogCard from "../../components/blogCard";
import BlogIcon from "../../assets/BlogCard.webp";
import Pagination from "../../components/pagination";

const Blog = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [blogData, setBlogData] = useState([]);

  // Todo:
  // const [filteredBlog, setFilteredBlog] = useState([]);
  // const [blogCategories, setBlogCategories] = useState([]);
  // const [activeIndices, setActiveIndices] = useState([]);
  // const [categoryDropdown, setCategoryDropdown] = useState(false);
  // const [searchValue, setSearchValue] = useState("");
  // const [debouncedValue] = useDebounce(searchValue, 700);
  // const [stuck, setStuck] = useState(false);
  // const dropdownRef = useRef(null);

  const [members, setMembers] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    Promise.all([GetBlog(), GetBlogCategories(), GetMembers()])
      .then(([researchRes, membersRes]) => {
        setBlogData(researchRes.data || []);
        // setFilteredBlog(researchRes.data || []);
        // setBlogCategories(categoriesRes.data || []);
        setMembers(membersRes.data || []);
      })
      .catch(() => {
        console.error("Error fetching data.");
      })
      .finally(() => setIsLoading(false));
  }, []);

  const [width, setWidth] = useState(window.innerWidth);
  const [smallCover, setSmallCover] = useState(false);

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    setSmallCover(width > 950);
  }, [width]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  // const [currentPage, setCurrentPage] = useState(1);
  // const [postsPerPage, setPostsPerPage] = useState(5);
  // const lastPostIndex = currentPage * postsPerPage;
  // const firstPostIndex = lastPostIndex - postsPerPage;
  // const currentPosts = blogData.slice(firstPostIndex, lastPostIndex);

  if (isLoading) {
    return <Loading />;
  }
  return (
    <div className="blog">
      <Fade
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        delay={600}
        direction="down"
        triggerOnce
      >
        <span style={{ textAlign: "center" }}>{t("blog")}</span>
        <h1>{t("blogInfo")}</h1>
      </Fade>

      <div className="blogContainer">
        {blogData.length > 0 ? (
          blogData.map((item, index) => {
            const date = parse(item.date, "yyyy.MM.dd", new Date());
            const formattedDate = format(date, "dd.MM.yyyy");
            return (
              <BlogCard
                key={index}
                categories={item.categories}
                // onCategoryClick={(catId) => {
                //   setActiveIndices([catId]);
                // }}
                onCategoryClick={() => {}}
                title={item.title}
                subtitle={item.subtitle}
                duration={item.time_to_read}
                views={item.views}
                date={formattedDate}
                image={
                  smallCover && item.cover_image
                    ? item.cover_image
                    : item.background_image
                }
                alias={item.alias}
              />
            );
          })
        ) : (
          <p style={{ textAlign: "center" }}>{t("Blogs not found!")}</p>
        )}

        {/* <div className="blogPagination">
          <Pagination
            // totalPosts={blogData.length}
            // postsPerPage={postsPerPage}
            // currentPage={currentPage}
            // setCurrentPage={setCurrentPage}
            {...{
              totalPosts: blogData.length,
              postsPerPage,
              currentPage,
              setCurrentPage,
            }}
          />
        </div> */}
      </div>
    </div>
  );
};

export default Blog;
