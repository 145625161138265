import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/Logo.svg";
import LogoMobile from "../../assets/LogoMobile.svg";
import Am from "../../assets/Armenia.svg";
import Ru from "../../assets/Russia.svg";
import US from "../../assets/US.svg";
import Menu from "../../assets/Menu.svg";
import { createPortal } from "react-dom";
import Close from "../../assets/Close.svg";
import { Accordion, AccordionItem } from "@szhsin/react-accordion";
import AccordionIcon from "../../assets/AccordionIcon.svg";
import { useTranslation } from "react-i18next";
import Loading from "../loading";
import { redirectToLogin, redirectToOnboarding } from "../../helpers/navigate";
import Search from "../../assets/Search.svg";
import navItemsData from "../../json/navItems.json";

const domain = process.env.REACT_APP_COOKIE_DOMAIN;

const Navigation = () => {
  const { t, i18n } = useTranslation();
  const [isModalOpened, setIsModalOpened] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const searchInput = useRef(null);
  const [searchMobileS, setSearchMobileS] = useState("visible");
  const [searchInputLength, setSearchInputLength] = useState();

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth > 1150) {
        setIsModalOpened(false);
      }
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const OpenMenu = () => {
    setIsModalOpened(true);
    document.getElementById("root").classList.add("block-scroll");
  };
  const CloseMenu = () => {
    setIsModalOpened(false);
    document.getElementById("root").classList.remove("block-scroll");
  };
  const handleLinkClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  };
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    // document.cookie = `i18next=${language}; SameSite=Lax; Domain=.${domain}; path=/; Secure`;
    const html = document.getElementsByTagName("html");
    html[0].setAttribute("lang", language);

    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  };

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 650);
  }, [location]);

  useEffect(() => {
    if (isLoading) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isLoading]);

  // Search
  const searchBar = () => {
    const element = document.querySelector(".login-buttons");
    if (
      (window.innerWidth < 1526 && searchInput.current?.value.trim() !== "") ||
      (window.innerWidth < 1526 &&
        document.activeElement === searchInput.current)
    ) {
      element.classList.add("hideLB");
    } else {
      setTimeout(() => {
        element.classList.remove("hideLB");
      }, 900);
    }
  };

  const handleSearchMobileS = () => {
    if (
      (window.innerWidth < 370 &&
        searchInput.current?.value.trim() !== "" &&
        !isModalOpened) ||
      (window.innerWidth < 370 &&
        document.activeElement === searchInput.current &&
        !isModalOpened)
    ) {
      setSearchMobileS("hidden");
    } else {
      setTimeout(() => {
        setSearchMobileS("visible");
      }, 800);
    }
  };

  // ---
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  const combinedHandler = () => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    setDebounceTimeout(
      setTimeout(() => {
        searchBar();
        handleSearchMobileS();
      }, 300)
    );
  };
  // ---

  // const combinedHandler = () => {
  //   searchBar();
  //   handleSearchMobileS();
  // };

  useEffect(() => {
    combinedHandler();

    if (searchInput.current) {
      searchInput.current.addEventListener("focus", combinedHandler);
      searchInput.current.addEventListener("blur", combinedHandler);
      searchInput.current.addEventListener("input", combinedHandler);
    }
    return () => {
      if (searchInput.current) {
        searchInput.current.removeEventListener("focus", combinedHandler);
        searchInput.current.removeEventListener("blur", combinedHandler);
        searchInput.current.removeEventListener("input", combinedHandler);
      }
    };
  }, [isModalOpened]);

  const searchValueChange = () => {
    setSearchInputLength(searchInput.current.value.length);
  };

  useEffect(() => {
    const filteredItems = navItemsData.navItems
      .map((item) => [
        { path: item.path, name: item.name.en },
        { path: item.path, name: item.name.ru },
        { path: item.path, name: item.name.am },
      ])
      .reduce((a, b) => [...a, ...b], [])
      .filter((item) =>
        item.name
          .toLowerCase()
          .includes(searchInput.current.value.toLowerCase())
      );

    if (searchInput.current.value.length > 0) {
      const handleKeyDown = (event) => {
        if (event.key === "Enter" && filteredItems.length > 0) {
          navigate(filteredItems[0].path);

          if (searchInput.current) {
            searchInput.current.value = "";
          }
          searchInput.current?.blur();
          setSearchInputLength(0);
          setIsModalOpened(false);
          document.getElementById("root").classList.remove("block-scroll");

          setTimeout(() => {
            document.querySelector(".login-buttons").classList.remove("hideLB");
            setSearchMobileS("visible");
          }, 800);
        }
      };

      document.addEventListener("keydown", handleKeyDown);

      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [searchInput.current?.value, navigate]);

  return (
    <>
      {isLoading && <Loading />}
      <div className="containerNavigation">
        <div className={isModalOpened ? "navigation fixedNav" : "navigation"}>
          <div>
            <NavLink onClick={CloseMenu} className="navLink" to="/">
              <img className="logo" src={Logo} alt="Logo" />
            </NavLink>
            <NavLink onClick={CloseMenu} className="navLink logoMobile" to="/">
              <img src={LogoMobile} alt="Logo" />
            </NavLink>
          </div>
          <ul className="nav-middle">
            <li className="startInvesting">
              {t("startInvesting")}
              <ul className="startInvestingH">
                <li>
                  <NavLink className="navLink" to="/armenian-markets">
                    {t("armenianMarket")}
                  </NavLink>
                </li>
                <li>
                  <NavLink className="navLink" to="/global-markets">
                    {t("globalMarket")}
                  </NavLink>
                </li>
                <li>
                  <NavLink className="navLink" to="/safekeeping">
                    {t("safeKeeping")}
                  </NavLink>
                </li>
                <li>
                  <NavLink className="navLink" to="/advisory">
                    {t("advisory")}
                  </NavLink>
                </li>
                <li>
                  <NavLink className="navLink" to="/brokerage">
                    {t("brokerage")}
                  </NavLink>
                </li>
                <li>
                  <NavLink className="navLink" to="/research">
                    {t("research")}
                  </NavLink>
                </li>
                <li>
                  <NavLink className="navLink" to="/blog">
                    {t("blog")}
                  </NavLink>
                </li>
                <li>
                  <NavLink className="navLink" to="/exchange-rates">
                    {t("exchangeRates")}
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="company">
              {t("company")}

              <ul className="companyH">
                <li>
                  <NavLink className="navLink" to="/about">
                    {t("aboutUs")}
                  </NavLink>
                </li>
                <li>
                  <NavLink className="navLink" to="/documents">
                    {t("documentsOld")}
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink className="navLink" to="/founding-documents">
                    {t("documents")}
                  </NavLink>
                </li>
                <li>
                  <NavLink className="navLink" to="/financial-statement">
                    {t("financialStatement")}
                  </NavLink>
                </li> */}
                <li className="legalActsLink">
                  {t("legalActs")}
                  <ul className="legalActsH">
                    <NavLink className="navLink" to="/internal-acts">
                      {t("internalLActs")}
                    </NavLink>
                    <NavLink className="navLink" to="/normative-acts">
                      {t("normativeLActs")}
                    </NavLink>
                  </ul>
                </li>
                <li>
                  <NavLink className="navLink" to="/news">
                    {t("news")}
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="plans">
              {t("plans")}

              <ul className="plansH">
                <li>
                  <NavLink className="navLink" to="/tariffs">
                    {t("tariffs")}
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="nContactUs">
              {t("contactUs")}

              <ul className="nContactUsH">
                <li>
                  <NavLink className="navLink" to="/contact">
                    {t("contactUs")}
                  </NavLink>
                </li>
                <li>
                  <NavLink className="navLink" to="/clients-rights">
                    {t("clientsRights")}
                  </NavLink>
                </li>
              </ul>
            </li>

            {!isModalOpened ? (
              <li className="language" style={{ visibility: searchMobileS }}>
                <ul className="languageH">
                  <li onClick={() => changeLanguage("am")}>
                    <img src={Am} alt="Armenia" /> {t("armenia")}
                  </li>
                  <li onClick={() => changeLanguage("ru")}>
                    <img src={Ru} alt="Russia" /> {t("russian")}
                  </li>
                  <li onClick={() => changeLanguage("en")}>
                    <img src={US} alt="US" /> {t("english")}
                  </li>
                </ul>
              </li>
            ) : null}

            {isModalOpened ? (
              <button onClick={CloseMenu} className="menu close">
                <img src={Close} alt="Close" />
              </button>
            ) : (
              <button
                onClick={() => {
                  OpenMenu();
                  handleLinkClick();
                }}
                style={{ visibility: searchMobileS }}
                className="menu"
              >
                <img src={Menu} alt="Menu" />
              </button>
            )}
          </ul>
          <div className="login-buttons">
            <button onClick={redirectToLogin}>{t("login")}</button>
            <button onClick={redirectToOnboarding} className="openAccount">
              {t("openAccount")}
            </button>
          </div>

          <div
            className={
              isModalOpened
                ? "searchContainer searchContainerMenu"
                : "searchContainer"
            }
            style={{ right: searchMobileS === "hidden" ? "24px" : undefined }}
          >
            <div className="search">
              <input
                onChange={searchValueChange}
                ref={searchInput}
                type="text"
                placeholder=" "
              />
              <div>
                <svg>
                  <use xlinkHref="#path" />
                </svg>
              </div>
            </div>

            <svg style={{ display: "none" }}>
              <symbol viewBox="0 0 160 28" id="path">
                <path
                  d="M32.9418651,-20.6880772 C37.9418651,-20.6880772 40.9418651,-16.6880772 40.9418651,-12.6880772 C40.9418651,-8.68807717 37.9418651,-4.68807717 32.9418651,-4.68807717 C27.9418651,-4.68807717 24.9418651,-8.68807717 24.9418651,-12.6880772 C24.9418651,-16.6880772 27.9418651,-20.6880772 32.9418651,-20.6880772 L32.9418651,-29.870624 C32.9418651,-30.3676803 33.3448089,-30.770624 33.8418651,-30.770624 C34.08056,-30.770624 34.3094785,-30.6758029 34.4782612,-30.5070201 L141.371843,76.386562"
                  transform="translate(83.156854, 22.171573) rotate(-225.000000) translate(-83.156854, -22.171573)"
                ></path>
              </symbol>
            </svg>

            {searchInputLength > 0 && (
              <div className="searchDropdown">
                <ul>
                  {navItemsData.navItems
                    .map((item) => [
                      { id: item.id, path: item.path, name: item.name.en },
                      { id: item.id, path: item.path, name: item.name.ru },
                      { id: item.id, path: item.path, name: item.name.am },
                    ])
                    .reduce((a, b) => [...a, ...b], [])
                    .filter((item) =>
                      item.name
                        .toLowerCase()
                        .includes(searchInput.current.value.toLowerCase())
                    )
                    .map((item) => (
                      <NavLink
                        key={item.id}
                        to={item.path}
                        onClick={() => {
                          if (searchInput.current) {
                            searchInput.current.value = "";
                          }
                          searchInput.current?.blur();
                          setSearchInputLength(0);
                          setIsModalOpened(false);
                          document
                            .getElementById("root")
                            .classList.remove("block-scroll");

                          setTimeout(() => {
                            document
                              .querySelector(".login-buttons")
                              .classList.remove("hideLB");
                            setSearchMobileS("visible");
                          }, 800);
                        }}
                      >
                        <li key={item.id}>{item.name}</li>
                      </NavLink>
                    ))}
                </ul>
              </div>
            )}
          </div>
        </div>
        {isModalOpened ? (
          <DropdownMenu
            onClose={CloseMenu}
            className={
              isModalOpened ? "dropdownMenu" : "dropdownMenu dropdownMenuOpen"
            }
          />
        ) : null}
      </div>
    </>
  );
};
export default Navigation;

export const DropdownMenu = ({ onClose, className }) => {
  const [acordion1, setAcordion1] = useState(false);
  const [acordion2, setAcordion2] = useState(false);
  const [acordion3, setAcordion3] = useState(false);
  const [acordion4, setAcordion4] = useState(false);
  const Acordion1 = () => {
    setAcordion1(!acordion1);
  };
  const Acordion2 = () => {
    setAcordion2(!acordion2);
  };
  const Acordion3 = () => {
    setAcordion3(!acordion3);
  };
  const Acordion4 = () => {
    setAcordion4(!acordion4);
  };

  const { t, i18n } = useTranslation();

  return createPortal(
    <div className={`${className} nav-dropdown-container`}>
      <div className="accordion">
        <Accordion allowMultiple transition transitionTimeout={250}>
          <AccordionItem
            // aria-expanded
            className="accordionItem"
            header={
              <div className="header" onClick={Acordion1}>
                <p>{t("startInvesting")}</p>
                <div className={!acordion1 ? "close" : "close open"}>
                  <img src={AccordionIcon} alt="accordionImg" />
                </div>
              </div>
            }
          >
            <ul className="menuUl">
              <NavLink
                onClick={onClose}
                className="navLink"
                to="/armenian-markets"
              >
                <li>{t("armenianMarket")}</li>
              </NavLink>
              <NavLink
                onClick={onClose}
                className="navLink"
                to="/global-markets"
              >
                <li>{t("globalMarket")}</li>
              </NavLink>
              <NavLink onClick={onClose} className="navLink" to="/safekeeping">
                <li>{t("safeKeeping")}</li>
              </NavLink>
              <NavLink onClick={onClose} className="navLink" to="/advisory">
                <li>{t("advisory")}</li>
              </NavLink>
              <NavLink onClick={onClose} className="navLink" to="/brokerage">
                <li>{t("brokerage")}</li>
              </NavLink>
              <NavLink onClick={onClose} className="navLink" to="/research">
                <li>{t("research")}</li>
              </NavLink>
              <NavLink onClick={onClose} className="navLink" to="/blog">
                <li>{t("blog")}</li>
              </NavLink>
              <NavLink
                onClick={onClose}
                className="navLink"
                to="/exchange-rates"
              >
                <li>{t("exchangeRates")}</li>
              </NavLink>
            </ul>
          </AccordionItem>

          <AccordionItem
            className="accordionItem"
            header={
              <div className="header" onClick={Acordion2}>
                <p>{t("company")}</p>
                <div className={!acordion2 ? "close" : "close open"}>
                  <img src={AccordionIcon} alt="accordionImg" />
                </div>
              </div>
            }
          >
            <ul className="menuUl">
              <NavLink onClick={onClose} className="navLink" to="/about">
                <li>{t("aboutUs")}</li>
              </NavLink>

              <NavLink onClick={onClose} className="navLink" to="/documents">
                <li>{t("documentsOld")}</li>
              </NavLink>
              {/* 
              <NavLink
                onClick={onClose}
                className="navLink"
                to="/founding-documents"
              >
                <li>{t("documents")}</li>
              </NavLink>

              <NavLink
                onClick={onClose}
                className="navLink"
                to="/financial-statement"
              >
                <li>{t("financialStatement")}</li>
              </NavLink> */}

              <li className="legalActsD">
                {t("legalActs")}
                <ul className="legalActsDH">
                  <NavLink
                    onClick={onClose}
                    className="navLink"
                    to="/internal-acts"
                  >
                    {t("internalLActs")}
                  </NavLink>
                  <NavLink
                    onClick={onClose}
                    className="navLink"
                    to="/normative-acts"
                  >
                    {t("normativeLActs")}
                  </NavLink>
                </ul>
              </li>

              <NavLink onClick={onClose} className="navLink" to="/news">
                <li>{t("news")}</li>
              </NavLink>
            </ul>
          </AccordionItem>

          <AccordionItem
            className="accordionItem"
            header={
              <div className="header" onClick={Acordion3}>
                <p>{t("plans")}</p>
                <div className={!acordion3 ? "close" : "close open"}>
                  <img src={AccordionIcon} alt="accordionImg" />
                </div>
              </div>
            }
          >
            <ul className="menuUl">
              <NavLink onClick={onClose} className="navLink" to="/tariffs">
                <li>{t("tariffs")}</li>
              </NavLink>
            </ul>
          </AccordionItem>

          <AccordionItem
            className="accordionItem"
            header={
              <div className="header" onClick={Acordion4}>
                <p>{t("contactUs")}</p>
                <div className={!acordion4 ? "close" : "close open"}>
                  <img src={AccordionIcon} alt="accordionImg" />
                </div>
              </div>
            }
          >
            <ul className="menuUl">
              <NavLink onClick={onClose} className="navLink" to="/contact">
                <li>{t("contactUs")}</li>
              </NavLink>

              <NavLink
                onClick={onClose}
                className="navLink"
                to="/clients-rights"
              >
                <li>{t("clientsRights")}</li>
              </NavLink>
            </ul>
          </AccordionItem>
        </Accordion>
      </div>
      <div className="footerMenu">
        <button onClick={redirectToLogin}>{t("login")}</button>
        <button onClick={redirectToOnboarding}>{t("openAccount")}</button>
      </div>
    </div>,
    document.getElementById("modal-root")
  );
};
