import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";
import ResearchCard from "../../components/researchCard";
import { GetMembers, GetResearch, GetResearchCategories } from "../../api";
import Loading from "../../components/loading";
import Filter from "../../assets/FilterCategories.svg";
import Search from "../../assets/SearchByCategory.svg";
import { Fade } from "react-awesome-reveal";
import { format, parse } from "date-fns";
import { enUS, ru, hy } from "date-fns/locale";
import { useDebounce } from "use-debounce";
import { getMLValue } from "../../helpers/ml";
import i18n from "../../language/18n";
import ResearchCategory from "../../components/researchCategory";

const Research = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [researchData, setResearchData] = useState([]);
  const [filteredResearch, setFilteredResearch] = useState([]);
  const [researchCategories, setResearchCategories] = useState([]);
  const [members, setMembers] = useState([]);
  const [activeIndices, setActiveIndices] = useState([]);
  const [categoryDropdown, setCategoryDropdown] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [debouncedValue] = useDebounce(searchValue, 700);
  const [stuck, setStuck] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    setIsLoading(true);
    Promise.all([GetResearch(), GetResearchCategories(), GetMembers()])
      .then(([researchRes, categoriesRes, membersRes]) => {
        setResearchData(researchRes.data || []);
        setFilteredResearch(researchRes.data || []);
        setResearchCategories(categoriesRes.data || []);
        setMembers(membersRes.data || []);
      })
      .catch(() => {
        console.error("Error fetching data.");
      })
      .finally(() => setIsLoading(false));
  }, []);

  // Handle category dropdown click outside
  const handleClickOutside = (event) => {
    dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      setCategoryDropdown(false);
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Handle scroll
  //! Open
  // useEffect(() => {
  //   const handleScroll = () => {
  //     setStuck(window.scrollY > 300);
  //   };
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  // Handle search
  useEffect(() => {
    if (searchValue && searchValue.length) {
      setSearchValue(searchValue);
    } else {
      setSearchValue("");
    }
    handleSearch();
  }, [debouncedValue, researchData]);

  // Handle category filter
  useEffect(() => {
    if (activeIndices.length === 0) {
      setFilteredResearch(researchData);
      return;
    }

    GetResearch(activeIndices)
      .then(({ data }) => {
        setFilteredResearch(data);
        console.log(filteredResearch);
      })
      .catch(() => {
        console.error("Error fetching filtered research.");
      });
  }, [activeIndices, researchData]);
  const isActive = (index) => activeIndices.includes(index);
  const toggleActiveIndex = (index) => {
    setSearchValue("");
    setActiveIndices((prev) => {
      return isActive(index)
        ? prev.filter((i) => i !== index)
        : [index, ...prev];
    });

    // console.log(researchData);
    // console.log(filteredResearch);
    // console.log(activeIndices);
    // console.log("==================================");
  };

  // Define handleSearch
  const handleSearch = () => {
    if (!searchValue.trim()) {
      setFilteredResearch(researchData);
      return;
    }
    const searchTerm = searchValue.toLowerCase();
    const filtered = researchData.filter((item) => {
      const combinedTitle = item?.title
        ? Object.values(item.title).join(" ").toLowerCase()
        : "";
      const combinedSubTitle = item?.subtitle
        ? Object.values(item.subtitle).join(" ").toLowerCase()
        : "";
      return (
        combinedTitle.includes(searchTerm) ||
        combinedSubTitle.includes(searchTerm)
      );
    });
    setFilteredResearch(filtered);
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="research">
      <Fade delay={600} direction="down" triggerOnce>
        <span style={{ textAlign: "center" }}>{t("research")}</span>
        <h1>{t("researchInfo")}</h1>
      </Fade>

      {stuck && (
        <div style={{ height: "40px", width: "0px", background: "red" }}></div>
      )}
      {researchData.length > 0 && (
        <div
          ref={dropdownRef}
          className={
            stuck
              ? "wtb-search-filter wtb-search-filter-stuck"
              : "wtb-search-filter"
          }
        >
          <img
            onClick={(e) => {
              categoryDropdown && setCategoryDropdown(false);
              handleSearch();
            }}
            className="wtb-search-icon"
            src={Search}
            alt="search"
          />
          <input
            onClick={() => categoryDropdown && setCategoryDropdown(false)}
            type="text"
            placeholder="Search"
            onChange={(e) => setSearchValue(e.target.value)}
            value={searchValue}
            onKeyDown={handleKeyPress}
          />

          <button
            onClick={(e) => {
              setCategoryDropdown(!categoryDropdown);
            }}
            style={{
              background: categoryDropdown
                ? "rgba(255, 255, 255, 0.9)"
                : "rgba(255, 255, 255, 0.5)",
            }}
            className="filter-categories"
          >
            <img src={Filter} alt="filter" />
          </button>

          {categoryDropdown && (
            <div className="filter-categories-dropdown">
              {researchCategories.map((i, index) => (
                <ResearchCategory
                  key={index}
                  onClick={() => {
                    toggleActiveIndex(i.id);
                  }}
                  name={getMLValue(
                    researchCategories[index].name,
                    i18n.language
                  )}
                  active={isActive(i.id)}
                />
              ))}
            </div>
          )}
        </div>
      )}

      <div className="researchContainer">
        {filteredResearch.length > 0 ? (
          filteredResearch.map((item, index) => {
            const locales = {
              en: enUS,
              ru: ru,
              am: hy,
            };
            const date = parse(item.date, "yyyy.MM.dd", new Date());
            const formattedDate = format(date, "d MMMM yyyy", {
              locale: locales[i18n.language] || enUS,
            });
            return (
              <ResearchCard
                key={index}
                title={item.title}
                subtitle={item.subtitle}
                categories={item.categories}
                onCategoryClick={(catId) => {
                  setActiveIndices([catId]);
                }}
                date={formattedDate}
                image={item.background_image}
                authors={members.filter((m) => item.authors.includes(m.id))}
                alias={item.alias}
              />
            );
          })
        ) : (
          <p style={{ width: "100%", textAlign: "center" }}>
            Research not found!
          </p>
        )}
      </div>
    </div>
  );
};

export default Research;
