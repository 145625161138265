import React, { useEffect, useRef, useState } from "react";
import "./styel.scss";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GetMembers, GetBlogByAlias } from "../../api";
import { format, parse, isValid } from "date-fns";
import Loading from "../../components/loading";
import Clock from "../../assets/Clock.svg";
import Eye from "../../assets/Eye.svg";
import Calendar from "../../assets/Calendar.svg";
import Blog from "../../assets/BlogCard.webp";
import BlogContent from "../../assets/BlogContent.webp";
import BlogInformation from "../../assets/BlogInformation.svg";
import { getMLValue } from "../../helpers/ml";
import { galleryImgURL } from "../../helpers/image";
import { documentDownloadURL } from "../../helpers/doc";
import TopUp from "../../assets/TopUp.svg";
import { Disclaimer } from "../researchPage";

const BlogPage = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [blogData, setBlogData] = useState([]);
  const [members, setMembers] = useState([]);
  const pageUpRef = useRef(null);
  // useEffect(() => {
  //   setIsLoading(true);
  //   GetMembers().then(({ data }) => {
  //     setMembers(data);
  //   });

  //   GetBlogByAlias(id)
  //     .then(({ data }) => {
  //       if (data) {
  //         setBlogData(data);
  //       }
  //     })
  //     .finally(() => {
  //       setIsLoading(false);
  //     });
  // }, [id]);

  useEffect(() => {
    setIsLoading(true);
    GetMembers().then(({ data }) => {
      setMembers(data);
    });

    GetBlogByAlias(id)
      .then(({ data }) => {
        if (data) {
          setBlogData(data);
          // console.log("Blog Data:", data);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  const pageUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    let oldScrollY = window.scrollY;

    window.onscroll = () => {
      if (window.scrollY > 700) {
        if (pageUpRef.current) {
          pageUpRef.current.style.opacity = "0.2";
          pageUpRef.current.style.display = "flex";
          pageUpRef.current.style.transition = "opacity 0.3s ease";
        }
        if (oldScrollY < window.scrollY) {
          if (pageUpRef.current) {
            pageUpRef.current.style.opacity = "0.2";
            pageUpRef.current.style.transition = "opacity 0.2s ease";
          }
        } else {
          if (pageUpRef.current) {
            pageUpRef.current.style.opacity = "1";
            pageUpRef.current.style.transition = "opacity 0.1s ease";
          }
        }
      } else {
        if (pageUpRef.current) {
          pageUpRef.current.style.opacity = "0";
          pageUpRef.current.style.display = "none";
        }
      }

      oldScrollY = window.scrollY;
    };

    return () => {
      window.onscroll = null;
    };
  }, []);

  const getBlogHtml = () => {
    let html = getMLValue(
      {
        en: blogData.content_en,
        ru: blogData.content_ru,
        am: blogData.content_am,
      },
      i18n.language
    );

    const el = document.createElement("div");
    el.innerHTML = html;

    const iframes = el.querySelectorAll("figure.media iframe");
    iframes.forEach((iframe) => {
      const src = iframe.getAttribute("src");
      if (src?.includes("vimeo")) {
        iframe.setAttribute(
          "src",
          src + "?loop=1&autoplay=1&muted=1&controls=0"
        );
      } else {
      }
    });

    const links = el.querySelectorAll("a");
    links.forEach((a) => {
      const src = a.getAttribute("href");
      if (src !== "/" && src?.startsWith("/")) {
        if (
          src.startsWith("/what-to-buy/blog") ||
          src.startsWith("/what-to-buy/research")
        ) {
          a.setAttribute("href", src.replace("/what-to-buy", ""));
        } else if (src.startsWith("/what-to-buy?tab=blog")) {
          a.setAttribute("href", "/blog");
        } else if (src.startsWith("/what-to-buy?tab=research")) {
          a.setAttribute("href", "/research");
        } else {
          a.setAttribute("href", "https://app.siriuscapital.am" + src);
        }
      } else {
        // pass
      }
    });

    return el.innerHTML;
  };

  if (isLoading) {
    return <Loading />;
  }

  const date = parse(blogData.date, "yyyy.MM.dd", new Date());
  const formattedDate = isValid(date)
    ? format(date, "dd.MM.yyyy")
    : "Invalid date";

  return (
    <div className="blogPage">
      <div className="blogPageHeader">
        <div className="blogPageHeaderLeft">
          <div className="blogCategoryWrapper">
            {blogData.categories.map((category, index) => (
              <span
                key={category.id}
                //todo Add
                // onClick={(e) => {
                //   onCategoryClick(category.id);
                // }}
              >
                {getMLValue(category.name, i18n.language)}
                {blogData.categories.length - 1 > index && ","}
              </span>
            ))}
          </div>
          <p>{getMLValue(blogData.title, i18n.language)}</p>

          <div>
            <p>
              <img src={Clock} alt="Clock" />
              {blogData.time_to_read} {t("minBlog")}.
            </p>
            {/* <p>
              <img src={Eye} alt="Eye" />
              {blogData.views}
            </p> */}
            <p>
              <img src={Calendar} alt="Calendar" />
              {formattedDate}
            </p>
          </div>
        </div>
        <img
          className="blogPageIcon"
          src={galleryImgURL(blogData.background_image)}
          alt="icon"
        />
      </div>
      {/* <div className="blogPageSubtitleSection">
        <p className="blogPageSubtitle">
          {getMLValue(blogData.subtitle, i18n.language)}
        </p>
        <div>
          <span>{t("author")}</span>
          <p>{t("teamSiriusCapital")}</p>
        </div>
      </div> */}
      <div
        className="blogPageContent"
        dangerouslySetInnerHTML={{
          __html: getBlogHtml(),
        }}
      ></div>

      <div className="disclaimer">
        <a
          target="_blank"
          href={documentDownloadURL(Disclaimer[i18n.language] || Disclaimer.en)}
          download
        >
          {t("disclaimer")}
        </a>
      </div>

      <button ref={pageUpRef} className="page-up" onClick={pageUp}>
        <img src={TopUp} alt="TopUp" />
      </button>
    </div>
  );
};

export default BlogPage;
