import React from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";
import CS1 from "../../docs/ՀԱՃԱԽՈՐԴՆԵՐԻ ԲՈՂՈՔՆԵՐԻ ՔՆՆՈՒԹՅԱՆ ԸՆԹԱՑԱԿԱՐԳ.pdf";
import CS2 from "../../docs/Ի՞ՆՉ ԱՆԵԼ, ԵԹԵ ՈՒՆԵՔ ԲՈՂՈՔ.pdf";
import CS3 from "../../docs/ԲՈՂՈՔԻ ՆԵՐԿԱՅԱՑՄԱՆ ՀԱՅՏ.pdf";

const ClientsRights = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="clientsRights">
      <h1>{t("clientsRights")}</h1>

      <div>
        <p>{t("CR1")}</p>
        <p dangerouslySetInnerHTML={{ __html: t("CR1_1") }}></p>

        <p style={{ marginBlock: "15px -15px" }}>
          <b>{t("CR2")}</b>
        </p>
        <ul>
          <li>{t("CR3")}</li>
          <li>
            {t("CR4")}
            <a target="_blank" href="mailto:support@siriuscapital.am">
              support@siriuscapital.am
            </a>
            {t("CR5")}
          </li>
          <li>{t("CR6")}</li>
        </ul>

        <p>
          {t("CR7")}
          <a target="_blank" href={CS1} download>
            {t("CR8")}
          </a>
        </p>

        <p style={{ marginBlock: "15px -15px" }}>
          <b>{t("CR9")}</b>
        </p>
        <ul>
          <li>{t("CR10")}</li>
          <li>{t("CR11")}</li>
          <li>
            {t("CR12")}
            <a target="_blank" href="mailto:support@siriuscapital.am">
              support@siriuscapital.am
            </a>
            {t("CR13")}
          </li>
        </ul>

        <p>{t("CR14")}</p>

        <p style={{ marginBlock: "15px -15px" }}>
          <b>{t("CR15")}</b>
        </p>
        <ul>
          <li>
            <a target="_blank" href={CS2} download>
              {t("CR16")}
            </a>
          </li>
          <li>
            <a target="_blank" href={CS3} download>
              {t("CR17")}
            </a>
          </li>
        </ul>

        <p style={{ marginTop: "15px" }}>
          <b>{t("CR18")}</b>
        </p>

        <p>
          {t("CR19")}
          <a target="_blank" href="https://fsm.am/">
            {t("CR20")}
          </a>{" "}
          {t("CR21")}
        </p>

        <p>{t("CR22")}</p>
        <p>{t("CR23")}</p>

        <p> {t("CR24")} +374 (60) 70-11-11</p>
        <p> {t("CR25")} +374 (10) 58-24-21</p>
        <p>
          {t("CR26")}{" "}
          <a target="_blank" href="mailto:info@fsm.am">
            info@fsm.am
          </a>
        </p>
        <p>
          {t("CR27")}{" "}
          <a target="_blank" href="https://fsm.am/">
            www.fsm.am
          </a>
        </p>

        <p style={{ marginTop: "8px" }}>{t("CR28")}</p>
        <p>{t("CR29")}</p>
        <p>{t("CR30")}</p>

        <p>
          <b>{t("CR31")}</b> {t("CR32")}
        </p>
      </div>
    </div>
  );
};

export default ClientsRights;
