import React, { useEffect, useRef, useState } from "react";
import "./styel.scss";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Author from "../../assets/DefaultAuthor1.svg";
import TopUp from "../../assets/TopUp.svg";
import { GetMembers, GetResearchByAlias } from "../../api";
import { getMLValue } from "../../helpers/ml";
import { format, parse, isValid } from "date-fns";
import { enUS, ru, hy } from "date-fns/locale";
import Loading from "../../components/loading";
import { galleryImgURL } from "../../helpers/image";
import { documentDownloadURL } from "../../helpers/doc";
import DocViewer, { PDFRenderer } from "@cyntler/react-doc-viewer";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

export const Disclaimer = {
  en: "DisclaimerReEN.pdf",
  ru: "DisclaimerReRU.pdf",
  am: "DisclaimerReAM.pdf",
};

const PDF = React.memo(
  ({ uri }) => {
    return (
      <DocViewer
        className="doc-viewer"
        documents={[{ uri, fileType: "pdf" }]}
        pluginRenderers={[PDFRenderer]}
        config={{
          header: {
            disableHeader: true,
            disableFileName: true,
          },
          pdfZoom: {
            defaultZoom: 1,
            zoomJump: 0.2,
          },
          pdfVerticalScrollByDefault: true,
        }}
      />
    );
  },
  (prev, next) => {
    return prev.uri === next.uri;
  }
);

const ResearchPage = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [researchData, setResearchData] = useState([]);
  const [formattedDate, setFormattedDate] = useState("");
  const [members, setMembers] = useState([]);
  const pageUpRef = useRef(null);

  useEffect(() => {
    setIsLoading(true);
    GetMembers().then(({ data }) => {
      setMembers(data);
    });

    GetResearchByAlias(id)
      .then(({ data }) => {
        if (data) {
          setResearchData(data);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  useEffect(() => {
    const locales = {
      en: enUS,
      ru: ru,
      am: hy,
    };

    if (researchData.date) {
      const parsedDate = parse(researchData.date, "yyyy.MM.dd", new Date());
      if (isValid(parsedDate)) {
        setFormattedDate(
          format(parsedDate, "d MMMM yyyy", {
            locale: locales[i18n.language] || enUS,
          })
        );
      }
    }
  }, [i18n.language, researchData.date]);

  const pageUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    let oldScrollY = window.scrollY;

    window.onscroll = () => {
      if (window.scrollY > 700) {
        if (pageUpRef.current) {
          pageUpRef.current.style.opacity = "0.2";
          pageUpRef.current.style.display = "flex";
          pageUpRef.current.style.transition = "opacity 0.3s ease";
        }
        if (oldScrollY < window.scrollY) {
          if (pageUpRef.current) {
            pageUpRef.current.style.opacity = "0.2";
            pageUpRef.current.style.transition = "opacity 0.2s ease";
          }
        } else {
          if (pageUpRef.current) {
            pageUpRef.current.style.opacity = "1";
            pageUpRef.current.style.transition = "opacity 0.1s ease";
          }
        }
      } else {
        if (pageUpRef.current) {
          pageUpRef.current.style.opacity = "0";
          pageUpRef.current.style.display = "none";
        }
      }

      oldScrollY = window.scrollY;
    };

    return () => {
      window.onscroll = null;
    };
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="researchPage">
      <div
        className="researchPageHeader"
        style={{
          backgroundImage: `url(${galleryImgURL(
            researchData.background_image
          )})`,
        }}
      >
        <div className="headerContent">
          {/* <Fade
            style={{ width: "80%" }}
            delay={400}
            direction="down"
            triggerOnce
          >
            <h1>{getMLValue(newsData.title, i18n.language)}</h1>
            <span>Story by Sirius Capital • {newsData.date}</span>
          </Fade> */}
          <div className="headerContentCategories">
            {researchData.categories.map((category) => (
              <p key={category.id}>
                {getMLValue(category.name, i18n.language)}
              </p>
            ))}
          </div>
          <h1>{getMLValue(researchData.title || "", i18n.language)}</h1>
          <div className="headerBottomContent">
            <span>{formattedDate}</span>
            <a
              href={documentDownloadURL(
                getMLValue(researchData.pdf, i18n.language)
              )}
              download
              target="_blank"
            ></a>
          </div>
        </div>
      </div>
      <div className="researchPageContent">
        <div className="authors">
          {members
            .filter((m) => researchData.authors.includes(m.id))
            .map((member) => (
              <div key={member.id} className="authors-field">
                <img
                  src={member.image ? galleryImgURL(member.image) : Author}
                  alt={getMLValue(member.name, i18n.language) || "Author"}
                />
                <div>
                  <p>{getMLValue(member.name, i18n.language)}</p>
                  <span>{getMLValue(member.role, i18n.language)}</span>
                </div>
              </div>
            ))}
        </div>
        <p
          style={{
            width: "100%",
            maxWidth: "800px",
            paddingBlock: "32px",
            textAlign: "left",
          }}
        >
          {getMLValue(researchData.subtitle || "", i18n.language)}
        </p>

        <div className="researchPageMaterial">
          <PDF
            uri={documentDownloadURL(
              getMLValue(researchData.pdf, i18n.language)
            )}
          />
        </div>

        <div className="disclaimer">
          <a
            target="_blank"
            href={documentDownloadURL(
              Disclaimer[i18n.language] || Disclaimer.en
            )}
            download
          >
            {t("disclaimer")}
          </a>
        </div>
      </div>
      <button ref={pageUpRef} className="page-up" onClick={pageUp}>
        <img src={TopUp} alt="TopUp" />
      </button>
    </div>
  );
};

export default ResearchPage;
